$mol_portion_indicator $mol_view
	style *
		^
		width <= width_style \0

$mol_portion $mol_view
	portion 0
	sub /
		<= Indicator $mol_portion_indicator
			width_style <= indicator_width_style \0
