$hyoo_js_perf_case_row $mol_view
	results /
	Eval $hyoo_js_eval
		code <= sample \
		run <= eval_showed
		Result => Eval_result
	sub <= columns /
		<= Prefix $mol_expander
			title @ \Setup
			expanded? <=> prefix_showed? true
			minimal_width 150
			Tools <= Prefix_tools $mol_bar sub /
				<= Measurable $mol_check_box
					checked? <=> measurable? true
					hint @ \Measutre on run
				<= Title $mol_string_button
					hint @ \Case Name
					enabled <= changable true
					value? <=> title? \
				<= Eval_sandalone $mol_link_iconed
					title \
					hint @ \Generated script
					uri <= eval_standalone \
			content / <= Prefix_code $mol_textarea
				enabled <= changable true
				spellcheck false
				value?val <=> prefix?val \
				hint \let count = {#}
		<= Source $mol_expander
			title @ \Measure
			expanded? <=> source_showed? true
			minimal_width 150
			Tools <= Source_tools $mol_view sub <= edit_tools /
				<= Drop $mol_button_minor
					click? <=> drop? null
					hint @ \Drop case
					sub /
						<= Drop_icon $mol_icon_close
				<= Dupe $mol_button_minor
					click? <=> dupe? null
					hint @ \Duplicate case
					sub /
						<= Dupe_icon $mol_icon_chevron_double_down
				<= Swap $mol_button_minor
					click? <=> swap? null
					hint @ \Swap cases
					sub /
						<= Swap_icon $mol_icon_unfold_more_horizontal
			content /
				<= Source_code $mol_textarea
					enabled <= changable true
					spellcheck false
					value?val <=> source?val \
					hint \res = {#} % count
		<= Eval_labeler $mol_expander
			expanded? <=> eval_showed? false
			title @ \Debug
			content / <= Eval_result
		<= Results $mol_view
			sub <= result_rows /
				<= Result*0 $hyoo_js_perf_case_result
					title <= result_title* \
					result <= result* $hyoo_js_perf_stats
				<= Result*1
				<= Result*2

