$mol_dump_value $mol_view
	value? null
	preview_show? true
	sub /
		<= Simple $mol_text_code
			text <= simple \
		<= Expand $mol_expander
			expanded? <=> expanded? false
			Trigger <= Expand_head $mol_check_expand
				minimal_height 24
				minimal_width 24
				expanded? <=> expanded? false
				expandable <= expandable true
				clicks? <=> expand_all? null
				label /
					<= Expand_title $mol_text_code
						text <= expand_title \
			content <= expand_content /
				<= Preview $mol_view sub /
					<= Preview_dom $mol_view
						dom_node <= preview_dom null
						render <= preview null
				<= Row*0 $mol_dump_list
					values <= row_values* /
					prototypes <= prototypes false
					preview_show <= preview_show
