$mol_select $mol_pick
	dictionary? *
	options /string
	value? \
	option_label_default \
	Option_row* $mol_button_minor
		event_click?event <=> event_select*?event null
		sub <= option_content* /
			<= Option_label* $mol_dimmer
				haystack <= option_label* \
				needle <= filter_pattern? \
	No_options $mol_view
		sub /
			<= no_options_message @ \No options
	plugins /
		^
		<= Nav $mol_nav
			keys_y <= nav_components /$mol_view
			current_y?component <=> option_focused?component null
			cycle? <=> nav_cycle? true
	hint @ \Pick..
	bubble_content /
		<= Filter
		<= Bubble_pane $mol_scroll
			sub /
				<= Menu $mol_list
					rows <= menu_content /$mol_view
	Filter $mol_search
		query? <=> filter_pattern? \
		hint <= filter_hint @ \Filter..
		submit?event <=> submit?event null
		enabled <= enabled true
	Trigger_icon $mol_icon_dots_vertical
