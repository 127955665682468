$hyoo_sync_online $mol_select
	minimal_width 40
	minimal_height 40
	yard $hyoo_sync_yard<any>
		master_list => dictionary
		master_cursor? => master_cursor?
	Filter null
	option_content* /
		<= Option_logo* $mol_avatar
			id <= master_id* \
		<= option_label*
	trigger_content /
		<= Link $mol_link
			uri <= master_link \
			sub <= link_content /
				<= Well $mol_avatar
					id <= master_link \
				<= Fail $mol_icon_sync_off
			hint <= message <= hint \$hyoo_sync
